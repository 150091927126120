<template>
<!-- https://bbbootstrap.com/snippets/bootstrap-colorful-navbar-animation-45860002 -->


<ChangeMyPassword v-if="isChangeMyPasswordVisible" @close="closeChangePassword" @save="saveChangePassword"></ChangeMyPassword>
<ConsulterInforiskClient v-if="isWatchingLogs" @close="closeWatchingLogs"></ConsulterInforiskClient>
<nav class="navbar navbar-expand-lg navbar-red navbar-dark">
    <div class="wrapper"> </div>
    <div class="wrapper1"> </div>
    
    <div class="container-fluid all-show"> 

       

        <!-- Brand part -->
        <!-- Brand part -->
        <div style="display: block; padding-right:50px; padding-left:10px;">
          <div class="">
            <a class="navbar-brand logoTitle" href="/" style="margin-right:0 !important; ">
              <!-- <div class="flex" style="align-items: center;">
                <div>MY SC</div>
                <div class="dotLogo flex" style="align-items: center; justify-content: center;">
                  <span class="pi pi-star" style="font-size: 1rem;color: #1e415c; "></span>
                </div>
                <div>RE</div>
              </div> -->
                <img class="logo" src="../assets/test4.png" />
            </a>
          </div>
          <div style="text-align: center; position: absolute;top: 44px;left: 47px;">
            <small style="font-size:1.3rem; color: white;font-family:DancingScript; "> </small> &nbsp;&nbsp;
          </div>
        </div>
        
        <!-- <div style="font-size: 2.5rem; font-weight: bold; padding-right: 50px;">
          <span class="p-d-inline-flex"> <i class="pi pi-building" style="font-size: 2rem;margin-left:30px"></i> {{currentClient.raisonSociale}}  </span>
        </div> -->
        
        
      
        <!-- Nav items part -->
       
            
            <!-- <div style="width:10%">
              2
              </div> -->
            
            <!-- Logout Part -->
            <div class="topnav-right" style="display:flex"> 
              <div>
              <ul class="navbar-nav mr-auto mb-2 mb-lg-0" style="right:0">
                <!-- Menu links -->
                <li class="nav-item">
                  
                  <div style="padding: 7px;">
                    <img src="../assets/francais.gif" @click="changeLang('FR')"/>
                  </div>
                  
                </li>
                <span style="color:white;bottom: 0;padding-top: 8px;">|</span>
                <li class="nav-item">
                  
                  <div style="padding: 7px;">
                    <img src="../assets/anglais.gif" @click="changeLang('EN')"/>
                  </div>
                  
                </li>
               
                  <!-- End Menu Links -->
              </ul>
            </div>

              <div class="dropdown" style="min-width:210px">
                <button @click="$router.push('/login')" class="btn" style="color:white" type="button" aria-expanded="false">
                  <span v-if="language == 'FR'">Se connecter</span>
                  <span v-if="language == 'EN'">Log in</span>
                </button>
                
              </div>
            </div>
            
    </div>
</nav>

</template>

<script>
import ChangeMyPassword from '../1-common-components/ChangeMyPassword.vue';
import ConsulterInforiskClient from '../1-common-components/ConsulterInforiskClient.vue';

import CRUDService from "../1-common-components/crud.service";

export default {
  name: "HeaderClient",
  //--------------------------- Components --------------------------
  components: {
      ChangeMyPassword,
      ConsulterInforiskClient
  },
  props:['lang'],
  emits:['changeLang'],
  //------------------------------ Data -----------------------------
  data() {
      return {
          isChangeMyPasswordVisible: false,
          isWatchingLogs: false,
          currentClient : {},
          language:'FR'
      }
  }, //end data()
  //--------------------------- Mounted --------------------------
  mounted() {
        this.log("@@@@@@@@@@@@@@"+this.currentUser.application);
        if(this.currentUser.application != "ClientApplication")
        {
          //alert("Espace non autorisé");
        }

        // alert("Font-Office en cours de dev ... revenez plutard !");
        // this.logOut();
  }, //end mounted
  //------------------------------ computed -----------------------------
  methods: {
    changeLang(lang)
    {
      this.language = lang
      this.$emit("changeLang",lang)
    },
  }
};
</script>

<style scoped>

/* @import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap'); */


.logoTitle
{
  font-family: 'Righteous', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  text-align: center;
  color: #000000;
  font-weight: bold;
}
.logo{
    height: 65px;
    padding-left: 5px;
    /* margin-top: -8px; */
    background-color: #d30400;
}


/* -------------- */


.navbar-nav>li>a {
    text-transform: uppercase;
    font-size: 14px;
    /* margin-right: 20px; */
    color: #fff
}

.navbar-toggler {
    padding: .20rem .50rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid white
}

.nav-link {
    color: #fff !important;
    cursor: pointer;
}
.nav-link:hover {
    color: rgb(224, 228, 21) !important;
    font-weight: bold;
    cursor: pointer;
}
.wrapper {
    width: 100%;
    position: absolute;
    height: 100%;
    /* background-color: #000; */
    background-color: #d30400;
    clip-path: polygon(81% 0, 100% 0, 100% 50%, 100% 100%, 71% 100%);
    transition: 1s all
}

.wrapper1 {
    width: 100%;
    position: absolute;
    height: 100%;
    /* background-color: #000; */
    background-color: #d30400;
    -webkit-clip-path: polygon(0% 0%, 0 100%, 10% 100%, 17% 0);
    clip-path: polygon(0% 0%, 0 100%, 10% 100%, 17% 0);
    transition: 1s all
}



.navbar-brand {
    color: #fff;
    margin-bottom: 4px;
    font-size: 27px
}

.navbar-red:hover .wrapper {
    clip-path: polygon(81% 0, 100% 0, 100% 50%, 100% 100%, 65% 100%)
}

.navbar-brand:hover {
    color: #fff
}

.navbar-red {
    background-color: #1e415c;
    color: #fff
}

.all-show {
    z-index: 10
}

.dotLogo {
  height: 25px;
  width: 25px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;

}

</style>





