<template>
  <Header @changeLang = "changeLang"/>
  <div class="mainContainer">
  <div class="container">
        <section>
            <h1>{{contentHomePage[language]["title"]}}</h1>
            <p style="font-size: 1.2rem;">{{contentHomePage[language]["description"]}}</p>
            <h2>{{contentHomePage[language]["secondTitle"]}}</h2>
            <ul>
                <li class="feature-item" :key="language" v-for="elem in contentHomePage[language]['functionalities']">
                    <strong class="feature-item_title">{{ elem.title }}</strong>
                    <p>{{ elem.description }}</p>
                </li>
            </ul>
        </section>
        <div class="subscription-panel">
            <h2>{{ contentHomePage[language]['rightCard']['title'] }}</h2>
            <p>{{ contentHomePage[language]['rightCard']['description'] }}</p>
            <a style="text-decoration:none" :href="contentHomePage[language]['rightCard']['link']"><button class="cta-button" ><span class="pi pi-envelope"></span> &nbsp; {{contentHomePage[language]['rightCard']['buttonTitle']}}</button></a>
        </div>
    </div>

  <!-- <Footer /> -->
  </div>
</template>

<script>
import Header from "./Header.vue";
import Footer from '../1-common-components/Footer.vue'

export default {
  name: "HomePage",
  props:['lang'],
  components: {
    Header,
    Footer
  },
  data() {
    return {
      language:'FR',
      contentHomePage:{
        'FR':{
          title:'Découvrez notre Outil de Scoring Clients !',
          description:'Vous souhaitez optimiser la gestion de crédit de vos clients et prendre des décisions commerciales éclairées ? Ne cherchez plus ! Notre Outil de Scoring de Clients de pointe est là pour vous aider.',
          secondTitle:'Fonctionnalités clés :',
          functionalities:[
            {title: 'Scoring Prédictif' ,description: 'Prédire le comportement et la valeur potentielle de vos clients.'},
            {title: 'Informations basées sur les données' ,description: "Obtenez des informations précieuses sur les préférences, les besoins et la propension de vos clients à s'engager, vous permettant d'adapter votre approche."},
            {title: 'Mises à jour en temps réel' ,description: 'Restez à jour avec les bilans de vos clients au fur et à mesure de leurs interactions avec votre entreprise, vous permettant de prendre des décisions opportunes.'},
            {title: 'Critères personnalisables' ,description: 'Définissez vos propres critères de scoring en fonction de vos objectifs commerciaux uniques et des exigences de votre secteur.'},
            {title: 'Intégration facile' ,description: 'Intégrez notre Outil de Scoring de Clients de manière transparente à votre CRM ou base de données existante pour une approche unifiée.'},
          ],
          rightCard:{title:'Pas encore Client ?',description:'Soyez parmi les premiers à profiter de nos fonctionnalités améliorées et à recevoir des offres exclusives en prenant contact avec nous dès maintenant.'
          ,buttonTitle:'Nous contacter'
          ,link:"mailto:contact@myscore.ma?subject=Demande%20d'information&body=Nous%20vous%20prions%20de%20bien%20vouloir%20nous%20laisser%20vos%20coordonn%C3%A9es%20afin%20que%20nous%20puissions%20vous%20contacter%20pour%20discuter%20de%20vos%20besoins%20et%20vous%20proposer%20notre%20solution.%0A%0APr%C3%A9nom:%0ANom:%0ASoci%C3%A9t%C3%A9:%0AFonction:%0AT%C3%A9l%C3%A9phone:%0AAdresse:%0AVille:%0APays:"}
        },
        'EN':{
          title:'Introducing our Client Scoring Tool!',
          description:'Are you looking to optimize your client management and make informed business decisions? Look no further! Our state-of-the-art Client Scoring Tool is here to help.',
          secondTitle:'Key Features:',
          functionalities:[
            {title: 'Predictive Scoring' ,description: 'Predict client behavior and potential value.'},
            {title: 'Data-driven Insights' ,description: "Gain valuable insights into your clients' preferences, needs, and likelihood to engage, empowering you to tailor your approach."},
            {title: 'Real-time Updates' ,description: "Stay up to date with your clients' balance sheets  as they interact with your business, allowing you to make timely decisions."},
            {title: 'Customizable Criteria' ,description: 'Define your own scoring criteria based on your unique business objectives and industry requirements.'},
            {title: 'Integration Friendly' ,description: 'Seamlessly integrate our Client Scoring Tool with your existing CRM or database for a unified approach.'},
          ],
          rightCard:{
            title:'Not yet a customer?',
            description:'Be among the first to take advantage of our enhanced features and receive exclusive offers by contacting us now.',
            buttonTitle:'Contact us',
            link:"mailto:contact@myscore.ma?subject=Information%20Request&body=Please%20leave%20us%20your%20details%20so%20that%20we%20can%20contact%20you%20to%20discuss%20your%20needs%20and%20propose%20our%20solution.%0A%0AFirst%20Name:%0ALast%20Name:%0ACompany:%0AJob%20Title:%0APhone:%0AAddress:%0ACity:%0ACountry:"
          }
        }
      },
      loading: false,
    };
  },
  
  computed: {
    loggedIn() {
      return this.$store.state.authStoreModule.status.loggedIn;
    },
    currentUser() {
      var currentClient = this.$store.state.clientScoringStoreModule.currentClient;
      var obj = {...this.$store.state.authStoreModule.user};
      obj.jsonUserRights = obj.jsonUserRights?JSON.parse(obj.jsonUserRights):{}

      if(currentClient && !currentClient.activerWorkflowValidation)
      {
        obj.jsonUserRights['validationQualitative'] = false
        obj.jsonUserRights['validationFinanciere'] = false
      }
      
      return obj;
    }
  },
  created() {
    if (this.loggedIn) {
      if(this.currentUser.application === "AdminApplication"){
        this.$router.push("/admin-clients");
      }
      else {
        this.$router.push("/client-societes");
      }
    }
  },
  mounted()
  {

  },

  methods: {
    changeLang(lang)
    {
      this.language = lang
    }
  },
};
</script>

<style scoped>

.mainContainer {
            font-family: Arial, sans-serif;
            line-height: 1.6;
            margin: 0;
            padding: 0;
            background-color: #f2f2f2;
            background-image: url("../assets/fond.jpg");
            background-size: cover;
            background-position: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            min-height: 100vh;
        }

        header img.logo {
            height: 50px;
        }

        .language-icons {
            display: flex;
            gap: 10px;
        }

        .container {
            display: flex;
            flex-direction: row;
            max-width: 1200px;
            width: 100%;
            margin-top: 20px;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
            border-radius: 5px;
            overflow: hidden;
        }

        .subscription-panel {
            flex: 3;
            background-color: #f5f5f5;
            color: #000;
            padding: 2rem;
        }

        .subscription-panel h2 {
            font-size: 1.5rem;
            white-space: nowrap; /* Prevent title from wrapping */
        }

        .subscription-panel p {
            font-size: 1.1rem;
            margin-top: 10px;
        }

        .subscription-panel button {
            display: block;
            padding: 10px 20px;
            background-color: #2ecc71;
            color: #fff;
            border: none;
            border-radius: 5px;
            margin-top: 20px;
            cursor: pointer;
            transition: background-color 0.3s ease;
            width: 100%; /* Make button full width */
        }

        .subscription-panel button:hover {
            background-color: #27ae60;
        }

        section {
            flex: 9;
            padding: 2rem;
            background-color: rgba(255, 255, 255, 0.7); /* Opacity and background color for main content */
        }

        ul {
            list-style: none;
            padding: 0;
        }

        li {
            padding-left: 20px; /* Add padding for bullet */
            padding-bottom: 10px;
            position: relative;
            cursor: pointer;
        }

        li::before {
            content: "\2022";
            position: absolute;
            left: 10px; /* Adjusted the positioning to include the bullet */
            color: #3498db;
            font-weight: bold;
        }

        .feature-item {
            background-color: transparent;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            padding-left: 10px;
            font-size: 1.2rem;
        }

        .feature-item:hover {
            background-color: rgba(255, 255, 255, 0.7); /* Opacity and background color for main content */
            border-radius: 5px; /* Add border radius on hover */
        }

        .feature-item:hover h3 {
            color: #38546c; /* Change title color on hover to blue-gray */
        }

        .feature-item:hover p {
            color: #333; /* Change content color on hover */
        }

        .cta-button {
            display: block;
            padding: 10px 20px;
            background-color: #3498db;
            color: #fff;
            text-decoration: none;
            border-radius: 5px;
            transition: background-color 0.3s ease;
            width: 100%; /* Make button full width */
            text-align: center; /* Center the button text */
        }

        .cta-button:hover {
            background-color: #2980b9;
        }

        .feature-item_title {
            padding-left: 15px;
            color: #146ca7;
        }


</style>
